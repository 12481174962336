<template>
  <section class="quote-section">
    <div class="quote-content">
      <img src="@/assets/img/reading-girl.png" alt="Reading girl" class="reading-image" />
      <div class="quote-container">
        <div class="quote-header">
          <h2>Quote of the Day</h2>
          <span class="date">{{ formattedDate }}</span>
        </div>
        <div class="quote-box">
          <i class="fas fa-quote-left quote-icon"></i>
          <p class="quote-text">{{ currentQuote.text }}</p>
          <p class="quote-author">― {{ currentQuote.author }}</p>
        </div>
        <div class="quote-actions">
          <button class="share-btn" @click="shareQuote">
            <i class="fas fa-share-alt"></i> Share
          </button>
          <button class="refresh-btn" @click="refreshQuote" :disabled="loading">
            <i class="fas fa-sync-alt" :class="{ 'fa-spin': loading }"></i>
            New Quote
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DailyQuoteSection',

  data() {
    return {
      loading: false,
      currentQuote: {
        text: '',
        author: ''
      }
    }
  },

  computed: {
    formattedDate() {
      return new Date().toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    }
  },

  methods: {
    cleanText(text) {
      return text
        .replace(/[^\x20-\x7E]/g, '') // Remove non-printable ASCII characters
        .replace(/Ç£|£Ç|ΓÇ£|£ÇÖ/g, '"') // Replace invalid quote characters
        .trim()
    },

    async fetchQuote() {
      this.loading = true
      try {
        const response = await fetch('https://notesandquotes.0xss.us/notes/random/')
        const data = await response.json()
        
        if (data.success && data.data) {
          this.currentQuote = {
            text: this.cleanText(data.data.content),
            author: this.cleanText(data.data.notebook_name)
          }
        } else {
          throw new Error('Failed to fetch quote')
        }
      } catch (error) {
        console.error('Error fetching quote:', error)
        this.currentQuote = {
          text: "Books are a uniquely portable magic.",
          author: "Stephen King"
        }
      } finally {
        this.loading = false
      }
    },

    async refreshQuote() {
      await this.fetchQuote()
    },

    shareQuote() {
      const quoteText = `"${this.currentQuote.text}" ― ${this.currentQuote.author}`

      if (navigator.share) {
        navigator.share({
          title: 'Daily Reading Quote',
          text: quoteText,
          url: window.location.href
        }).catch(console.error)
      } else {
        navigator.clipboard.writeText(quoteText)
          .then(() => alert('Quote copied to clipboard!'))
          .catch(console.error)
      }
    }
  },

  mounted() {
    this.fetchQuote()
  }
}
</script>

<style scoped>
.quote-section {
  border-radius: 18px;
  margin: 3.6rem;
  position: relative;
  overflow: hidden;
}

.quote-content {
  display: flex;
  align-items: center;
  gap: 3.6rem;
  max-width: 1200px;
  margin: 5.4rem auto;
}

.reading-image {
  width: 270px;
  height: auto;
}

.quote-container {
  flex: 1;
}

.quote-header {
  margin-bottom: 1.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quote-header h2 {
  font-size: 1.8rem;
  background: linear-gradient(to right, #282772, #f27cf9);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.date {
  color: #666;
  font-size: 0.8rem;
}

.quote-box {
  background: rgba(40, 39, 114, 0.05);
  padding: 1.8rem;
  border-radius: 13px;
  position: relative;
  margin: 0.9rem 0;
}

.quote-icon {
  color: #f27cf9;
  font-size: 1.35rem;
  margin-bottom: 0.9rem;
}

.quote-text {
  font-size: 1.35rem;
  line-height: 1.5;
  font-style: italic;
  margin-bottom: 0.9rem;
  color: #282772;
}

.quote-author {
  color: #666;
  font-weight: 500;
  text-align: right;
}

.quote-actions {
  display: flex;
  gap: 0.9rem;
  margin-top: 1.35rem;
  justify-content: flex-end;
}

.share-btn,
.refresh-btn {
  padding: 0.67rem 1.35rem;
  border-radius: 22px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.45rem;
  transition: all 0.3s ease;
}

.share-btn {
  background: transparent;
  border: 1px solid #282772;
  color: #282772;
}

.refresh-btn {
  background: #f27cf9;
  color: white;
}

.share-btn:hover {
  background: #282772;
  color: white;
}

.refresh-btn:hover {
  background: #282772;
}

.refresh-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .quote-content {
    flex-direction: column;
    text-align: center;
    gap: 1.4rem;
    margin: 2.1rem auto;
  }

  .reading-image {
    width: 210px;
  }

  .quote-header h2 {
    font-size: 1.4rem;
  }

  .quote-box {
    padding: 1.4rem;
  }

  .quote-text {
    font-size: 1.05rem;
    line-height: 1.4;
    margin-bottom: 0.7rem;
  }

  .quote-icon {
    font-size: 1.05rem;
    margin-bottom: 0.7rem;
  }

  .quote-actions {
    justify-content: center;
    margin-top: 1.05rem;
    gap: 0.7rem;
  }

  .quote-header {
    flex-direction: column;
    gap: 0.7rem;
    margin-bottom: 1.4rem;
  }

  .share-btn,
  .refresh-btn {
    padding: 0.52rem 1.05rem;
    font-size: 0.9rem;
  }
}
</style>